/**
 * activity page (TODO: unify all of the center submission JS to one file)
 */
/* define $ as jQuery just in case */
(function ($) {
  /* doc ready */
  $(function () {
    /* function to call console.log */
    function showlog(msg) {
      /* set debug mode, default is false, true is turn on and false is off */
      var DEBUG = true;
      if (DEBUG) {
        console.log(msg);
      }
    }

    // $(window).keydown(function (e) {
    //     let focused = $(':focus');
    //     if (focused.is('label.checker') && e.which == 32) {
    //         e.preventDefault();
    //         focused.click();
    //     }
    // });

    /* generic function for activities checker open and reset */
    $("#Activity-edit-page").on("click", ".activities_checker", function (e) {
      if (api_state_flag == 1 || view_only_mode_flag == 1) {
        return false;
      }

      e.preventDefault();
      showlog("activities checker clicked on line 34 or around...");

      /* set vars */
      var form = $(this).parents("form");
      var item = $(this).parents(".item");
      var participants_attended = $(item).next().next().find(".number-of-participants-input");
      var total_hours = $(item).next().next().next().find(".number-of-hours-input");

      /* add the active class to the parent item */
      $(item).toggleClass("active");

      updateItemRowInputs(item);

      /* toggle the disabled attribute to the next item */
      //   $(participants_attended).prop("readonly", !$(participants_attended).prop("readonly"));
      //   $(participants_attended).val(0);
      //   $(total_hours).prop("readonly", !$(total_hours).prop("readonly"));
      //   $(total_hours).val(0);

      console.log("activity save data 4");
      result = save_data_2(form);
    });

    /* on click of any button in the numeric steppers and apr-activities-form */
    $("#apr-activities-form .numeric-stepper button").click(function (e) {
      if (api_state_flag == 1 || view_only_mode_flag == 1) {
        return false;
      }

      e.preventDefault();
      var form = $(this).parents("form");

      /* save data via AJAX (to parent form action) - defined in data.js */
      console.log("activity save data 1");
      result = save_data_2(form);
    });

    /* on keyup change, focusout for any input and button in the apr-activities-form */
    $("#apr-activities-form .numeric-activities input").on("change, keyup, focusout", function (e) {
      if (api_state_flag == 1 || view_only_mode_flag == 1) {
        return false;
      }
      showlog("LINE #75: #apr-activities-form .numeric-activities input]').on(\"change, keyup, focusout");
      showlog("apr-activities-form click");
      e.preventDefault();
      var form = $(this).parents("form");

      /* save data via AJAX (to parent form action) - defined in data.js */
      console.log("activity save data 2");
      save_data_2(form);
    });

    // on change for the CCR radio
    $("#apr-activities-form input:radio").change(function (e) {
      if (api_state_flag == 1 || view_only_mode_flag == 1) {
        return false;
      }

      showlog("ccr click");
      e.preventDefault();
      var form = $(this).parents("form");

      /* save data via AJAX (to parent form action) - defined in data.js */
      console.log("activity save data 3");
      save_data_2(form);
    });

    $(document).on("click", ".save-activities-btn", function (e) {
      if (api_state_flag == 1 || view_only_mode_flag == 1) {
        window.location.replace($(".activityform").attr("next"));
        return false;
      }

      e.preventDefault();
      // clear auto save cache
      localStorage.clear();

      $.ajax({
        url: $(".activityform").attr("action"),
        type: "POST",
        data: $(".activityform").serialize(),
        success: function (res) {
          window.location.replace($(".activityform").attr("next"));
        },
        error: (err) => {
          $(".notification span").text("Error!");
          $(".notification p").text(err.message);
          $(".notification").addClass("active warning");
        },
      });
    });

    // was in review page, now deprecated
    $(document).on("click", ".edit-activities-section", function () {
      if (api_state_flag == 1 || view_only_mode_flag == 1) {
        return false;
      }

      $("html, body").animate(
        {
          scrollTop: 0,
        },
        900,
        function () {
          $(".activities").addClass("visible").removeClass("completed-section");
          $(".review-section").removeClass("visible");
          $(".progress-title").html("Activities");
        }
      );
    });

    // go to review page, deprecated
    $(".review-activities-btn").click(function (e) {
      if (api_state_flag == 1 || view_only_mode_flag == 1) {
        return false;
      }

      e.preventDefault();

      var $form = $('form[name="apr-activities-form"]').serialize();

      console.log("activity save data 2 ([.review-activities-btn] clicked)");

      $.ajax({
        url: "/ajax/review_activity",
        type: "POST",
        data: $form,
        success: function (res) {
          if (res) {
            $(".review-section").html(res);
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              900,
              function () {
                $(".activities-section").removeClass("visible").addClass("completed-section");
                $(".review-section").addClass("visible");
                //$('.progress-title').html('Review');
              }
            );
          } else {
            alert("Unable to process request");
          }
        },
        error: (err) => {
          console.log("Error Activity: ", err);
        },
      });
    });

    function get_total() {
      var part_sum = 0;
      $(".number-of-participants-input").each(function () {
        part_sum += Number($(this).val());
      });
      //$('#part-sum').text(part_sum);

      var hour_sum = 0;
      $(".number-of-hours-input").each(function () {
        hour_sum += Number($(this).val());
      });
    }

    function toggleActivityRowEnabled(rowNumber, enabled) {
      const SELECTOR = "";

      $(SELECTOR).prop("disabled", enabled ? false : true);
    }

    get_total();

    var api_state_flag = 0;
    var view_only_mode_flag = 0;
    $.fn.activities_view_only = function () {
      var form = this;
      api_state_flag = $(form).find('input[name="api_state_flag"]').val();
      view_only_mode_flag = $(form).find('input[name="view_only_mode_flag"]').val();
      showlog("activity.js => the api_state_flag IS SET to " + api_state_flag);
      showlog("activity.js => the view_only_mode_flag IS SET to " + view_only_mode_flag);
      if (api_state_flag == 1 || view_only_mode_flag == 1) {
        $(".number-of-participants-input, .number-of-hours-input").attr("disabled", "disabled");
        $("label.checker").removeAttr("tabindex");
      }
    };

    $("#apr-activities-form").activities_view_only();

    initialFormInputsEnableDisable();
  }); /* end doc ready */
})(jQuery);

/**
 *
 */
function initialFormInputsEnableDisable() {
  // get all the 'items'
  const items = $("table.activity_records td.activity.item");

  items.each((index, i) => {
    updateItemRowInputs(i);
  });
}

/**
 *
 * @param {*} item
 * @author Martin Halla <martin@halladesign.com>
 *
 * goes through the form on load and enabled disables the inputs bases on the checkbox
 */
function updateItemRowInputs(item) {
  const parentTrRow = $(item).parents("tr.activity-edit-row");
  const inputs = $(parentTrRow)
    .children("td")
    .children("input[type=text].number-of-participants-input, input[type=text].number-of-hours-input");

  if ($(item).hasClass("active")) {
    $(item).children("input[type=checkbox]").val("on");

    inputs.each((i, e) => {
      $(e).prop("disabled", false);
    });
  } else {
    $(item).children("input[type=checkbox]").val("off");
    // if item has been unselected, set values to null
    ($(item).next().next().find(".number-of-participants-input")).val("");
    ($(item).next().next().next().find(".number-of-hours-input")).val("");
    
    inputs.each((i, e) => {
      $(e).prop("disabled", true);
    });
  }
}
