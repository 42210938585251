/* check user authenticated AJAX call (refresh page if logged out) */
function attempt_page_reload(){
    let exempt_pages = ['/login', '/help'];
    let current_url = window.location.pathname;

    for (const page of exempt_pages) {
        if(current_url == page){
            return false;
        }
    }
    window.location.reload();
}

function sess_timeout() {
    var csrf = $('input[name="csrf_token_name"]').val();
    setTimeout(function () {
        $.ajax({
            url: '/ajax/validateLogin',
            type: 'GET',
            cache: false,
            data: {
                csrf_token_name: csrf
            },
            success: function (data) {
                if (data != '1') {
                    attempt_page_reload();
                } else {
                    sess_timeout();
                }
            }
        });
    }, 10000); /* 10 sec loop validation*/
}

/* init only once since it loops on its own */
sess_timeout();
