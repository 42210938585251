/**
 * outcome page (TODO: unify all of the center submission JS to one file)
 */
/* define $ as jQuery just in case */
(function ($) {

const SELECTOR_SUBMIT_BUTTON = 'div[type=submit].save-outcomes-btn';
const SUBMIT_GPRA_SECTION_ALL = -1;
// the last GPRA section - total of 6 - starting with zero
const SUBMIT_GPRA_SECTION_LAST = 5;

    /* global lets */
    let scroll_speed = 2000;

    /* check status / set open sections for each continue step group on page load (only do this once) */
    $.fn.form_wizard = function () {
        /** ================
         * static lets
         * ================ */
        let form = this;
        let use_button_wizard_flag = $(form).find('input[name="use_button_wizard_flag"]');
        let outcome_form_section = $(form).find('#outcome_form_section');

        /** ==============
         * on page load
         * ============== */
        set_default_state();

        set_open_groups();
        set_summary_groups();

        /** ================
         * event listeners
         * ================ */

        /* reset button click */
        $(form).on('click', '#outcome_reset_button', function (e) {

            /* confirm first w/ active class */
            if ($(this).hasClass('active')) {

                /* set the wizard form state (which resets the whole form) */
                set_wizard_form_state();

                /* delete the outcome with outcome type children */
                let delete_url = $('#outcome_reset_button').attr('data-delete-url');
                $.ajax({
                    url: delete_url,
                    type: 'GET',
                    data: [],
                    success: function (data) {

                    },
                });
            } else {
                $(this).addClass('active');
            }
        });

        /* **************************************************************
         * close the reset button if user clicks outside it
         * *************************************************************/
        $('body').click(function (e) {

            let reset_button = $(form).find('#outcome_reset_button');
            if (!reset_button.is(e.target) && reset_button.has(e.target).length === 0) {
                $(reset_button).removeClass('active');
            }
        });



        /* **************************************************************
        *  No Data to report click
        * ***************************************************************/
        $('#Outcome-edit-page').on('click', 'label.checker', function (e) {
            if (api_state_flag == 1 || view_only_mode_flag == 1) {
                return false;
            }

            e.preventDefault();

            let outcome_checker = $(this).parents('.checker_outcome');
            let nd2rFlag        = $(this).siblings('input.no_data_to_report_flag');
            let gpraSection     = $(this).attr('data-gpra-section');
            let unavailableStudentData = $(this).parents('.noscroll-table').find('.container-unavailable-student-data');

            console.log("outcome_checker");
            console.log(outcome_checker);
            // unchecking
            if ($(nd2rFlag).val() == 1) {
                $(nd2rFlag).val(0);
                enableGpraFormSection(gpraSection, true);
                outcome_checker.toggleClass('active');
                unavailableStudentData.find("textarea").val("");
                unavailableStudentData.show();

                validateGpraSection(gpraSection);
                saveOutcomeData($('#outcome-form'), $(this), gpraSection);

            } else {
                $(nd2rFlag).val(1);
                if (window.confirm('This will erase all GPRA values - do you wish to continue?')) {

                    // submit only this section
                    setSubmitGpraSectionNumber(gpraSection);

                    resetGpraSectionInputs(gpraSection);
                    enableGpraFormSection(gpraSection, false);

                    outcome_checker.toggleClass('active');
                    unavailableStudentData.hide();

                    saveOutcomeData($('#outcome-form'), $(this), gpraSection);
                } else {
                    $(nd2rFlag).val(0);
                    //$(outcome_checker).removeClass('active');

                    enableGpraFormSection(gpraSection, true);
                    validateGpraSection(gpraSection);
                }

            }

            // toggle values

            let form  = $(this).parents('form');

        });


        /* outcome continue button (after selecting outcome types) */
        $(form).on('click', '#outcome_continue_button', function (e) {

            e.preventDefault();

            /* set lets */
            let continue_buttons = $(form).find('.continue-button');
            let continue_steps = $(form).find('.continue_step');
            let active_steps = $(form).find('.step_group.active .continue_step');
            let step_titles = $(form).find('.step_group.active .step_title');
            let first_step = active_steps[0];
            let first_step_title = step_titles[0];

            /* set the open steps and indexes */
            set_open_groups();
            set_summary_groups();
            set_active_indexes();

            /* set the element states */
            show_outcome_btn(false);
            disable_outcome_btn(true);
            set_outcome_checkbox_state(false, false);

            /* toggle visibility */
            $(first_step).addClass('visible').removeClass('hidden').removeAttr('style');
            // Speed enhancement, make all input visible
            $(first_step_title).addClass('visible').removeClass('hidden').removeAttr('style');
            $(continue_buttons).parents('.button-wrap').addClass('visible').removeClass('hidden').removeAttr('style');

            /* open the main form section */
            slide_fade($(outcome_form_section), true);

            /* scroll to the top of the form */
            $('html, body').stop().animate({
                'scrollTop': $('#outcome_form_section').offset().top - 100
            }, scroll_speed, 'swing');

            /* save data via AJAX (to parent form action) - defined in data.js */
            save_data(form);


            // Speed enhancement, make all input visible
            $('#outcome_form_section').removeClass('hidden');
            set_submit_section_state(true);

        });

        /* click function on continue buttons */
        $(form).on('click', '.step_group.active .continue-button', function (e) {
            e.preventDefault();

            let active_steps = $(form).find('.step_group.active .continue_step');
            let current_step = $(this).parents('.continue_step');
            let current_index = parseInt(current_step.attr('data-index'));
            let next_index = parseInt(current_index + 1);

            /* hide the continue button and show the next step in the group */
            if (parseInt(next_index) < parseInt($(form).find('.step_group.active .continue_step').length)) {
                let next_el = $(active_steps).eq(next_index);
                let next_el_parent = $(next_el).parents('.step_group');
                let next_title = $(next_el_parent).find('.step_title');
                slide_fade(next_title, true);
                slide_fade(next_el, true);

                /* scroll to the top of the next section */
                $('html, body').stop().animate({
                    'scrollTop': $(next_el).offset().top - 100
                }, scroll_speed, 'swing');
            } else {
                disable_review_btn(false);
                slide_fade($('#submit-section'), true);

                /* scroll to the top of the next section */
                $('html, body').stop().animate({
                    'scrollTop': $('#submit-section').offset().top - 160
                }, scroll_speed, 'swing');
            }
        });

        /** ================
         * functions
         * ================ */
        /* set the state on page load depending on if button wizard flag is set */
        function set_default_state() {
            if (use_button_wizard_flag.val() == '1') {
                set_wizard_form_state();
            } else {
                set_open_form_state();
            }
        }

        /**
         * set custom attributes for the index on all active steps
         * NOTE: doing it this way because index() causes problems when not siblings
         */
        function set_active_indexes() {
            let continue_steps = $(form).find('.continue_step');
            let active_steps = $(form).find('.step_group.active .continue_step');
            $.each(continue_steps, function (i, field) {
                $(field).attr('data-index', false);
            });
            $.each(active_steps, function (i, field) {
                $(field).attr('data-index', i);
            });
        }

        /* set the wizard form state (on page load if use_button_wizard_flag == 1 or if the user resets) */
        function set_wizard_form_state() {

            /* set lets */
            let target_inputs = form.find('.numeric-stepper input');
            let target_counters = form.find('.context-counter');

            /* we zero out the inputs so they don't remain since we're not reloading page */
            target_inputs.val('0');
            form.find('.zero_target').html('0'); /* resetting the update targets as well */
            form.find('.zero_max_target').attr('data-max-value', '0'); /* resetting the update targets as well */
            form.find('.error-container').html(''); /* erase the errors */

            /* resetting the context counters as well */
            target_counters.removeClass('almost over').addClass('good');
            target_counters.find('.message').html('Great!');
            target_counters.find('.counter-current-count').attr('data-value', 0).text('0');
            target_counters.find('.counter-target-count').attr('data-value', 0).text('0');

            /* set the post flag to 0 (no longer shows in review) */

            /* set button and control element states */
            disable_reset_btn(true);
            set_outcome_checkbox_state(true, true);
            show_outcome_btn(true);
            disable_outcome_btn(true);
            disable_review_btn(true);

            /* notification errors on bottom */
            $('#Outcome-edit-page .notification p').html('');
            $('#Outcome-edit-page .notification').removeClass('active').removeClass('warning');

            /* reset the progress bar */
            $('.progress').delay(300).animate({
                width: '0px'
            }, 500);
        }

        /* open form state: all active continue steps open / etc. */
        function set_open_form_state() {
            /* set lets */
            let outcome_form_section = $(form).find('#outcome_form_section');
            let continue_buttons = $(form).find('.continue-button');
            let continue_steps = $(form).find('.continue_step');

            /* reset the buttons */
            disable_reset_btn(false);
            show_outcome_btn(false);
            disable_outcome_btn(true);

            /* set the open steps and indexes */
            set_open_groups();
            set_summary_groups();

            /* toggle visibility */
            set_submit_section_state(true);

        }

        /* set the reset button state */
        function disable_reset_btn(disable) {
            /* set lets */
            let reset_button = $(form).find('#outcome_reset_button');
            let is_disabled = $(reset_button).is(':disabled');

            /* if set to open, show the parent and remove disable attribute */
            if (disable == true) {
                if (!is_disabled) {
                    $(reset_button).prop('disabled', true).removeClass('active');
                }
            } else { /* enable the button */
                if (is_disabled) {
                    $(reset_button).prop('disabled', false).removeClass('active');
                }
            }
        }

        /* set the outcome type checkboxes state */
        function set_outcome_checkbox_state(active, empty) {
            /* set lets */
            let outcome_checkbox_group = $(form).find('#outcome_types');
            let toggle_triggers = $(outcome_checkbox_group).find('.group_toggle_trigger');
            let post_flags = $(outcome_checkbox_group).find('.post_flag');

            /* if set to active, add the "on" class so the checkboxes are clickable */
            if (active == true) {
                $(outcome_checkbox_group).addClass('on').removeClass('off').removeAttr('style');
            } else {
                $(outcome_checkbox_group).addClass('off').removeClass('on').removeAttr('style');
            }

            /* empty the checkbox values (if required as with the reset functionality) */
            if (empty == true) {
                $(toggle_triggers).removeClass('active');
                set_open_groups();
                set_summary_groups();
            }
        }

        /* set the outcome continue button state (continue button after outcome types) */
        function show_outcome_btn(show) {
            /* set lets */
            let outcome_continue_button = $(form).find('#outcome_continue_button');
            let outcome_btn_wrap = $(outcome_continue_button).parents('.button-wrap');

            /* if set to open, show the parent and remove disable attribute */
            if (show == true) {
                $(outcome_continue_button).addClass('visible').removeClass('hidden').removeAttr('style');
                slide_fade($(outcome_btn_wrap), true);
            } else {
                $(outcome_continue_button).addClass('hidden').removeClass('visible').removeAttr('style');
                slide_fade($(outcome_btn_wrap), false);
            }
        }

        /* set the outcome continue button state (continue button after outcome types) */
        function disable_outcome_btn(disable) {

            /* set lets */
            let outcome_continue_button = $(form).find('#outcome_continue_button');
            let is_disabled = $(outcome_continue_button).is(':disabled');

            /* if set to open, show the parent and remove disable attribute */
            if (disable == true) { /* disable the button */
                if (!is_disabled) {
                    $(outcome_continue_button).prop('disabled', true);
                }
            } else { /* enable the button */
                if (is_disabled) {
                    $(outcome_continue_button).prop('disabled', false);
                }
            }
        }

        /* set the review button state */
        function disable_review_btn(disable) {
            /* set lets */
            let review_button = $(form).find('#review-button');
            let is_disabled = $(review_button).is(':disabled');

            /* if set to open, show the parent and remove disable attribute */
            if (disable == true) { /* disable the button */
                if (!is_disabled) {
                    $(review_button).prop('disabled', true).removeClass('active');
                }
            } else { /* enable the button */
                if (is_disabled) {
                    $(review_button).prop('disabled', false).removeClass('active');
                }
            }
        }

        /* check open groups and set targets accordingly */
        function set_open_groups() {
            /* set lets */
            let data_groups = $(form).find('.data_group');
            let toggle_triggers = $(form).find('.group_toggle_trigger');
            let outcome_checkbox_group = $(form).find('#outcome_types');

            /* loop through the toggle triggers and catch the open groups */
            let open_groups = [];
            $.each(toggle_triggers, function (i, field) {
                let target = $(this).attr('rel');
                let target_el = form.find('#' + target);
                let target_step_groups = target_el.find('.step_group');
                if ($(field).hasClass('active') || $(field).hasClass('selected')) {
                    open_groups[i] = field;
                    $(target_el).addClass('visible').removeClass('hidden').removeAttr('style');
                    $(target_step_groups).addClass('active').removeAttr('style');
                } else {
                    $(target_el).addClass('hidden').removeClass('visible').removeAttr('style');
                    $(target_step_groups).removeClass('active');
                }
            });

            /* check for at least one open group to enable disable outcome continue button (also check if button wizard is set, otherwise, it is an edit page and should not be shown)*/
            if (open_groups.length >= 0) {
                disable_outcome_btn(false);
                disable_reset_btn(false);
            } else {
                disable_outcome_btn(true);
                disable_reset_btn(true);
            }

            /* check if outcome type checker is on or off - reset button MUST be on if the checker is off */
            if ($(outcome_checkbox_group).hasClass('off')) {
                disable_reset_btn(false);
            }
        }

        /* set the submit section state (summaries and review button) */
        function set_summary_groups() {
            /* set lets */
            let summary_groups = $(form).find('.summary_group');
            let active_toggle_triggers = $(form).find('.group_toggle_trigger.active');

            /* hide all by default */
            $(summary_groups).addClass('hidden').removeClass('visible').removeAttr('style');

            /* loop through the toggle triggers and catch the open groups */
            let open_groups = [];
            $.each(active_toggle_triggers, function (i, field) {
                let target = $(this).attr('rel');;
                $('.summary_' + target).addClass('visible').removeClass('hidden').removeAttr('style');
            });
        }

        /* set the submit section state (summaries and review button) */
        function set_submit_section_state(show) {
            let submit_section = $(form).find('#submit-section');

            /* if set to open, show the parent and remove disable attribute */
            if (show == true) {
                $(submit_section).addClass('visible').removeClass('hidden').removeAttr('style');
            } else {
                $(submit_section).addClass('hidden').removeClass('visible').removeAttr('style');
            }
        }


        /* function to animate progress bar on top */
        function progress_bar_animate() {

            /* count the active steps by visibility */
            let progress_bar = $('.progress-bar');
            let progress_bar_width = progress_bar.width();
            let all_active_steps = $(form).find('.step_group.active .continue_step');
            let completed_steps = $(form).find('.step_group.active .continue_step.visible');
            if (all_active_steps.length > 0) {
                let progress_percent = completed_steps.length / all_active_steps.length;
                let progress_width = Math.round(progress_percent * progress_bar_width) + 'px';
            } else {
                let progress_width = '0px';
            }

            /* animate it */
            $('.progress').delay(300).animate({
                width: progress_width
            }, 500);
        }
    }

    /* setting up a counter plugin to separate counter groups */
    $.fn.counter = function () {

        /* set lets */
        let counter_group = this;
        let form = counter_group.parents('form');
        let control_inputs = counter_group.find('.control_trigger .numeric-stepper input');
        let control_triggers = counter_group.find('.control_trigger .numeric-stepper button');
        let target_inputs = counter_group.find('.target_trigger .numeric-stepper input');
        let target_triggers = counter_group.find('.target_trigger .numeric-stepper button');
        let context_counter = counter_group.find('.context-counter');
        let message = context_counter.find('.message');
        let current_count = counter_group.find('.counter-current-count');
        let target_count = counter_group.find('.counter-target-count');

        /* click function on stepper buttons (controls which add up to the target count) */
        control_triggers.click(function (e) {
            e.preventDefault();
            fire_control_trigger();
        });

        /* keyup function on numeric-stepper inputs */
        control_inputs.keyup(function () {
            fire_control_trigger();
        });

        /* click function on stepper buttons */
        target_triggers.click(function (e) {
            e.preventDefault();
            fire_target_trigger();
        });

        /* keyup function on numeric-stepper inputs */
        $(target_inputs).keyup(function () {
            fire_target_trigger();
        });

        /**
         * functions
         */
        /* the function on the control trigger for counter / updating actions */
        function fire_control_trigger() {
            let current_value = 0;

            /* increment the current value and pass to current count el */
            $.each(control_inputs, function (i, field) {
                current_value += parseInt($(field).val());
            });

            /* make sure it's a number */
            if (!isNaN(current_value)) {
                current_count.attr('data-value', current_value).text(current_value);
            }

            /* make sure it's a number */
            let new_value = 0;
            if (!isNaN(current_value)) {
                let new_value = current_value;
            }
            current_count.attr('data-value', new_value).text(new_value);

            /* call the set_context function */
            set_context();
        }

        /* the function on the target trigger for the context counter (target value calculated by related field values) */
        function fire_target_trigger() {
            let target_value = 0;
            /* increment the current value and pass to current count el */
            $.each(target_inputs, function (i, field) {
                target_value += parseInt($(field).val());
            });

            /* make sure it's a number */
            let new_value = 0;
            if (!isNaN(target_value)) {
                let new_value = target_value;
            }
            target_count.attr('data-value', new_value).text(new_value);

            /* call the set_context function */
            set_context();
        }

        /* set the contextual classes / message for the counter */
        function set_context() {
            /* set lets */
            let current_value = parseInt(current_count.attr('data-value'));
            let target_value = parseInt(target_count.attr('data-value'));

            /* set the classes and messages on the counter */
            context_counter.removeClass('good almost over error-field');
            counter_group.find('.counter-error').html('');
            if (current_value == target_value) {
                context_counter.addClass('good');
                message.text('Great!');
            } else if (current_value > target_value) {
                let err_message = 'Please balance the values in this section.';
                context_counter.addClass('over error-field');
                message.text('Over!');
                counter_group.find('.counter-error').html('<span class="error-text">' + err_message + '</span>');
            } else {
                let err_message = 'Please balance the values in this section.';
                context_counter.addClass('almost error-field');
                message.text('Almost!');
                counter_group.find('.counter-error').html('<span class="error-text">' + err_message + '</span>');
            }
        }

        set_context(); /* run once on init */
    }

    /* form-wide function to update values using custom attributes */
    $.fn.value_updater = function () {

        /* set lets */
        let form = this;
        let update_triggers = $(form).find('.update_trigger .numeric-stepper button');
        let update_inputs = $(form).find('.update_trigger .numeric-stepper input');

        /* click function on stepper buttons (controls which add up to the target count) */
        update_triggers.click(function (e) {
            e.preventDefault();
            fire_value_updater($(this));
        });

        /* keyup function on numeric-stepper inputs */
        update_inputs.keyup(function () {
            fire_value_updater($(this));
        });

        /* fire the value updater */
        function fire_value_updater(el) {
            let parent_el = el.parents('.update_trigger');
            let update_value = parseInt(parent_el.find('.numeric-stepper input').val());
            let update_target = parent_el.attr('data-update-target');

            /* update the target els (spans) with the new input */
            let target_value = 0;
            if (!isNaN(update_value)) { /* make sure it's a number */
                let target_value = update_value;
            }
            $(form).find('.' + update_target).text(target_value);
        }
    }

    /* form-wide function to update values using custom attributes */
    $.fn.max_value_updater = function () {

        /* set lets */
        let form = this;
        let update_max_triggers = $(form).find('.update_max_trigger .numeric-stepper button');
        let update_max_inputs = $(form).find('.update_max_trigger .numeric-stepper input');

        /* click function on stepper buttons (controls which add up to the target count) */
        update_max_triggers.click(function (e) {
            e.preventDefault();
            fire_max_value_updater($(this));
        });

        /* keyup function on numeric-stepper inputs */
        update_max_inputs.keyup(function () {
            fire_max_value_updater($(this));
        });

        /* fire the value updater */
        function fire_max_value_updater(el) {
            let parent_el = el.parents('.update_max_trigger');
            let update_max_value = parseInt(parent_el.find('.numeric-stepper input').val());
            let update_max_target = parent_el.attr('data-max-update');

            /* update the target els (spans) with the new input */
            let target_value = 0;
            if (!isNaN(update_max_value)) { /* make sure it's a number */
                let target_value = update_max_value;
            }
            $(form).find('.' + update_max_target).attr('data-max-value', target_value);

        }
    }

    /* form-wide function to update absolute maximum values on fields (necessary to keep ceiling from stored values) */
    $.fn.abs_max_updater = function () {

        /* set lets */
        let form = this;
        let update_abs_triggers = $(form).find('.update_abs_trigger .numeric-stepper button');
        let update_abs_inputs = $(form).find('.update_abs_trigger .numeric-stepper input');

        /* click function on stepper buttons (controls which add up to the target count) */
        update_abs_triggers.click(function (e) {
            e.preventDefault();
            fire_abs_value_updater($(this));
        });

        /* keyup function on numeric-stepper inputs */
        update_abs_inputs.keyup(function () {
            fire_abs_value_updater($(this));
        });

        /* fire the absolute value updater */
        function fire_abs_value_updater(el) {
            let parent_el = el.parents('.update_abs_trigger');
            let update_abs_target = parent_el.attr('data-max-update');
            let update_abs_value = parseInt(parent_el.find('.numeric-stepper input').val());
            let abs_max = parseInt(parent_el.attr('data-abs-max'));

            /* set the limiting maximum based on whether the absolute is greater than the target value */
            if (update_abs_value > abs_max) {
                let limit_max = abs_max;
            } else {
                let limit_max = update_abs_value;
            }

            /* update the target els (spans) with the new input */
            let target_value = 0;
            if (!isNaN(limit_max)) { /* make sure it's a number */
                let target_value = limit_max;
            }
            $(form).find('.' + update_abs_target).attr('data-abs-max', target_value);

        }
    }

    /* form-wide function to check maximum values */
    function check_max_values(form) {

        /* set lets */
        let max_value_targets = $(form).find('.max_value_target');

        /* reset the error state first */
        max_value_targets.removeClass('error-field notify-field');
        max_value_targets.find('.error-container').html('');

        /* loop through each max_value_target element to validate maximum value */
        $.each(max_value_targets, function (i, field) {
            /* set lets */
            let curr_val = parseInt($(this).find('.numeric-stepper input').val());
            let max_val = parseInt($(this).attr('data-max-value'));

            /* conditionals to set the top ceiling maximum */
            if ($(this).attr('data-abs-max') > 0) {
                let abs_max = parseInt($(this).attr('data-abs-max'));
                let limit_max = abs_max > max_val ? max_val : abs_max;
            } else {
                let limit_max = max_val;
            }

            /* throw the error if the value is higher than the limiting maximum (the max value or the absolute max (parent limitation) */
            if (curr_val > limit_max) {
                let err_message = 'This number cannot be greater than ' + limit_max + '.';
                $(field).addClass('error-field notify-field');
                $(field).find('.error-container').html('<span class="error-text">' + err_message + '</span>');
            }
        });

        /* check the error state */
        check_error_state(form);

        /* check the error state for each group (for the summary section on top) */
        check_error_state_by_subgroup(form);
    }

    /* check error state by checking for error messages && counter status */
    function check_error_state(form) {
        /* set lets */
        let form_ok = true;
        let error_fields = $(form).find('.notify-field');

        /* if the number of errors is greater than 0, throw the error flag */
        if ($(error_fields).length > 0) {
            form_ok = false;
        }

        /* if the form is OK, enable the review button etc. */
        if (form_ok == true) {
            $(form).find('.review-btn').prop('disabled', false);

        } else {
            $(form).find('.review-btn').prop('disabled', true);
            /* pass the first error message */
            let first_err_message = $(error_fields[0]).find('.error-container').html();
            $('#Outcome-edit-page .notification p').html(first_err_message);
            $('#Outcome-edit-page .notification').addClass('active').addClass('warning');
            $('#Outcome-edit-page .notification span').html('Alert!');
        }
    }

    /**
     * check error state for each group by checking for error messages && counter status
     * (to show complete status in top summary)
     */
    function check_error_state_by_subgroup(form) {
        /* set lets */
        let groups = $(form).find('.data_subgroup');

        /* loop through the groups */
        $.each(groups, function (i, field) {
            let group_ok = true;
            let error_fields = $(groups[i]).find('.error-field');
            let id_slug = $(groups[i]).attr('id');

            /* if the number of errors is greater than 0, throw the error flag */
            if (error_fields.length > 0) {
                group_ok = false;
            }

            /* if the form is OK, enable the review button etc. */
            if (group_ok == true) {
                $(form).find('#outcome_errors #summary_' + id_slug + ' .box i').removeClass('incomplete').addClass('complete');
                $(form).find('#outcome_errors #summary_' + id_slug + ' .message').text('');
                $(field).find('.continue-button').prop('disabled', false); /* disable the continue buttons */
            } else {
                $(form).find('#outcome_errors #summary_' + id_slug + ' .box i').removeClass('complete').addClass('incomplete');
                $(form).find('#outcome_errors #summary_' + id_slug + ' .message').text('Please fix the errors above');
                $(field).find('.continue-button').prop('disabled', true); /* enable the continue buttons */
            }

        });
    }

    /* function for a nice slide-fade toggle */
    function slide_fade(el, show) {
        let speed = 400;
        if (show == true) {
            if (el.is(":hidden")) {
                el.stop(true, true).fadeIn({
                    duration: speed,
                    queue: false
                }).css('display', 'none').slideDown(speed, function () {
                    el.addClass('visible').removeClass('hidden');
                });
            }
        } else {
            if (el.is(":visible")) {
                el.stop(true, true).slideUp(speed, function () {
                    el.addClass('hidden').removeClass('visible');
                }).fadeOut({
                    duration: speed,
                    queue: false
                });
            }
        }
    }

    /* doc ready */
    $(function () {

        OutcomeValidation.showErrorMessage();
        /**
         * Disable GPRA areas that have no data to report
         */
        $('.no_data_to_report_flag').map(function(i, input) {

            if ($(input).val() == 1 ) {
                let gpraSection = $(input).attr('data-gpra-section');
                enableGpraFormSection(gpraSection, false);
            }
        });

        // this calculates all the GPRA improvements values on load
        calculateImprovementsResultsValues();

        // show all input errors
        let sectionsValidationResults = validateAllGpraSections(true);

        // and dispay the Unavailable student Dat textareas if necessary
        sectionsValidationResults.results.unavailableStudentsDataAmount.forEach((unavailableStudentsAmount, gpraSection) => {
            toggleShowUnavailableStudentDataTextArea(gpraSection, unavailableStudentsAmount);
        })


        /* function on the checkboxes to trigger AJAX save */
        $('#outcome_errors .scrollto').click(function (e) {
            e.preventDefault();

            /* set lets */
            let target = this.hash;

            /* animate to the hash */
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - 100
            }, scroll_speed, 'swing', function () {
                window.location.hash = target;
            });
        });

        /* init the form wizard continue step checker on page load (determines which get shown by default)*/
        $('#outcome-form').form_wizard();

        /* init the counter plugin on the counter groups (separately)*/
        $('#counter_group_prek_5').counter();
        $('#counter_group_6_12').counter();

        /* init the value updater on the form */
        $('#outcome-form').value_updater();

        /* init the value updater on the form */
        $('#outcome-form').max_value_updater();

        /* init the abs max value updater on the form */
        $('#outcome-form').abs_max_updater();

        /* fire the max value checker on the form on page load */
        check_max_values($('#outcome-form'));

        /* check max values on clicks and keyups */
        $('#outcome-form .numeric-stepper button').click(function (e) {
            e.preventDefault();
            let form = $(this).parents('form');
            let data_group = $(this).parents('.data_group');
            /* fire the max value checker */
            check_max_values(form);

            /* save data via AJAX (to parent form action) - defined in data.js */
            save_data(form);
        });

        $('#outcome-form .numeric-stepper input').keyup(function (e) {
            e.preventDefault();
            let form = $(this).parents('form');
            let data_group = $(this).parents('.data_group');
            /* fire the max value checker */
            check_max_values(form);

            /* save data via AJAX (to parent form action) - defined in data.js */
            save_data(form);
        });

        $('#outcome-form .numeric-stepper input').focusout(function () {
            let form = $(this).parents('form');
            let data_group = $(this).parents('.data_group');
            /* fire the max value checker */
            check_max_values(form);
        });

        /* function to call console.log */
        function showlog(msg) {
            /* set debug mode, default is false, true is turn on and false is off */
            let DEBUG = true;
            if (DEBUG) {
                console.log(msg);
            }
        }

        /* ******************************************************************
            on keyup change, focusout for any input and button in the outcome-form
        *************************************************************************/
        $('#outcome-form input').on("change", function(e) {
            e.preventDefault();
            const gpraSection = $(this).attr('data-gpra-section');
            let validatedSectionResult = validateGpraSection(gpraSection, true)
            if ( validatedSectionResult.value === true) {
                saveOutcomeData($('#outcome-form'), $(this), gpraSection);
            } else {
                // todo call error msg function
                validateGpraFormInput($(this));
            }

            toggleShowUnavailableStudentDataTextArea(gpraSection, validatedSectionResult.unavailableStudentsDataAmount)

        });



        $('div.container-unavailable-student-data textarea').blur((e) =>  {

            //getgpraSection
            const gpraSection = $(e.target).attr('data-gpra-section');

            if (validateGpraSection(gpraSection)) {
                // save secttion only
                saveOutcomeData($('#outcome-form'), $(e.target), gpraSection);

            }
        })

        /* pop warning message when outcomes gray out */
        $('#outcomes_button').click(function () {
            if ($('#outcomes_button').attr('href') == '#') {
                $('.notification p').html('You must enter Participation data before you can enter Outcomes data');
                $('.notification').addClass('active').addClass('warning');
                $('.notification span').html('Alert!');
            }
        });

        /* go to review page */
        $('#outcome-form .review-btn').click(function (e) {
            e.preventDefault();

            /* set lets */
            let form = $(this).parents('form');
            let form_data = $(form).serialize();
            $.ajax({
                url: '/ajax/review_outcome',
                type: 'POST',
                data: form_data,
                success: function (res) {
                    if (res) {
                        $('.review-section').html(res);
                        $('html, body').animate({
                            scrollTop: 0
                        }, 900, function () {
                            $('.outcomes').removeClass('visible').addClass('completed-section');
                            $('.review-section').addClass('visible');
                            //$('.progress-title').html('Review');
                        });
                    } else {
                        alert('Unable to process request');
                    }
                }
            });
        });

        function showSubmissionErrorsInfoDiv(data) {

            $('.container.outcomes-submit-errors').show();

            // hide all links
            $('.container.outcomes-submit-errors a.gpra-error-section-link').css('display', 'none !important');


            //get gpras
            const keys = Object.keys(data.sections)
            keys.forEach(function(gpra) {

                const selector = '.container.outcomes-submit-errors a.gpra-error-section-link[data-gpra-section=' + gpra + ']';

                $(selector).css('color', 'red');

              $(selector).show();
            }) ;
        }

        /***************************************************************
         * FORM SUBMIT BUTTON
         ***************************************************************/
        $(document).on('click', '.save-outcomes-btn', function (e) {

            e.preventDefault();
            let me = $(this);
            let form = $('.grade-participationform');

            // TODO
            // error hints need to be update if user fixed the errors

            let formErrors = validateAllGpraSectionsAndGetErrors();

            // set all sections to be submitted
            if (! formErrors['value']) {
                alert('Please correct the errors');
                showSubmissionErrorsInfoDiv(formErrors);
                return false;
            }

            let countEmpty = validateGpraEmpty();
            if (! countEmpty['value']) {
                alert('No fields may be left blank. If you have no data to report, enter a zero.');
                showSubmissionErrorsInfoDiv(countEmpty);
                return false;
            }

            // clear auto save cache
            localStorage.clear();

            $.ajax({
                url: $('#outcome-form').attr('action'),
                type: 'POST',
                data: $('#outcome-form').serialize(),
                success: function (res) {
                    if (me.hasClass('navigate-participation')) {
                        window.location.replace($('.outcomeform').attr('prev'));
                    } else {
                        // Different cuz this is the last one.
                        $('.notification span').text('Success!');
                        $('.notification p').text('You have successfully submitted your Outcomes.');
                        $('.notification').addClass('active success');
                    }
                },
                error: function(err) {
                    $('.notification span').text('Error!')
                    $('.notification p').text(err.message);
                    $('.notification').addClass('active warning');
                }
            });
        });



        $(document).on('click', '.edit-outcome-section', function () {
            $('html, body').animate({
                scrollTop: 0
            }, 900, function () {
                $('.outcomes').addClass('visible').removeClass('completed-section');
                $('.review-section').removeClass('visible');
            });
        });

        $(document).on('click', '.save-outcome-btn', function (e) {
            e.preventDefault(); // clear auto save cache
        });

        /* if no outcomes get selected, turn on the whoops message */
        if ($('.info').hasClass('NoOutcomes')) {
            $('.notification p').html('You cannot enter your Outcomes data until after your SEA Super User defines which Outcomes to reports. Please contact your SEA or SEA Super User for more information.');
            $('.notification').addClass('active').addClass('warning');
            $('.notification span').html('Alert!');
        }

        if ($('div').hasClass('empty')) {
            $('.notification p').html('No Participants Over 30 Days Found.');
            $('.notification').addClass('active').addClass('warning');
            $('.notification span').html('Alert!');
        }

    });

    /**
     * This sets teh
     * @param {*} submitGpraSection
     */
     function setSubmitGpraSectionNumber(submitGpraSection) {
        // 'gpra1' passed for example ...

        let gpraSectionNumber = SUBMIT_GPRA_SECTION_ALL;


        // 2 passed for example
        if (!isNaN(submitGpraSection)) {
            gpraSectionNumber =  submitGpraSection;

        } else  if (submitGpraSection.substring(0,4) === 'gpra') {
            gpraSectionNumber = submitGpraSection.substring(4);

            if (isNaN(gpraSectionNumber)) {
                gpraSectionNumber =  SUBMIT_GPRA_SECTION_ALL;
            }
        // default to all
        } else {

            gpraSectionNumber  = SUBMIT_GPRA_SECTION_ALL;
        }

        if (! (gpraSectionNumber >= SUBMIT_GPRA_SECTION_ALL && gpraSectionNumber <= SUBMIT_GPRA_SECTION_LAST)) {
          gpraSectionNumber = SUBMIT_GPRA_SECTION_ALL;
        }

       $('#submit-gpra-section').val(gpraSectionNumber);

    }


    function saveOutcomeData(form, element, submitGpraSection = SUBMIT_GPRA_SECTION_ALL) {

        // directive for backend - what section to save
        setSubmitGpraSectionNumber(submitGpraSection);

       let siblings =  $(element).siblings('.status-message');
       let column = $(element).attr('data-gpra-column');

        /* clear the local (browser) storage */
        localStorage.clear();

        /* set lets */
        let action = form.attr('action');
        let currentOutcomeId =  $('#edit-outcome-id').val();
        /* store form data to DB w/ AJAX on click (filter and sanitize values, etc. in model) */
        $.ajax({
            url: action,
            type: 'POST',
            data: form.serialize(),
            success: function (result) {

                if (result.status == 'success') {
                    /**
                     * update the FORM's current_outcome_id
                     * tp prevent double record createion
                     */
                    if (result.code == 201 && currentOutcomeId == 0) {
                        $('#edit-outcome-id').val(result.data.outcome.id);
                    } else {

                    }
                    siblings.removeClass('error status');
                    siblings.addClass('missing-student-data');
                    updateGpraImprovementsText(submitGpraSection, column);
                    showStatusMessage('GPRA section saved', siblings);


                    validateGpraFormInput(element);

                    return true;
                } else if ('error' == result.status) {


                } else {
                    return false;
                }
            },
            error: (err) => {
                // TODO: get the real message - this only gets 'OK'
                showErrorStatusMessage('Error: ' + err.statusText, siblings);
                return false;
            }
        });
    }

    function hideStatusMessage(el) {
        $(el).html('');
        $(el).css('visibility', 'hidden');
    }

    function showErrorStatusMessage(message, el, timerLength = 0) {
  
        $(el).html(message);
        $(el).css('visibility', 'visible');
        $(el).addClass('error');
        $(el).removeClass('success missing-student-data');

        if (timerLength > 0) {

            setTimeout(function() {
                    $(el).css('visibility', 'visible');
                    $(el).html('');
                }, timerLength);
        } else {
            $(el).css('visibility', 'visible');
        }

        return el;
    }

    /**
     * Shows timedout status message next to the edit input
     *
     * @param {*} message
     * @param {*} el
     */
    function showStatusMessage(message, el, passedColor, additionalStyles, timerLength) {
        $(el).html(message);
        $(el).css('visibility', 'visible');

        // the class will stay dark blue
        $(el).removeClass('error');
        $(el).removeClass('success');


         let timer = 0;
        if (undefined != timerLength && timerLength > 0) {
           timer = timerLength;

        }

        if (undefined != additionalStyles && (typeof additionalStyles == 'object')) {
            $(el).css(additionalStyles);
        }

        if (undefined != passedColor && undefined == timerLength) {
            color = passedColor;

            if (color == 'red') {
                timer = 3000;
            }
        }

        if (timer > 0) {
        
            setTimeout(function() {
                    $(el).css('visibility', 'visible');
                    $(el).html('');
                }, timer);
        } else {
            $(el).css('visibility', 'visible');
        }
    }

    function validateGpraEmpty() {
        let errors = 0;
        let sectionErrors = {};

        let gpras = ['gpra0', 'gpra1', 'gpra2', 'gpra3', 'gpra4', 'gpra5'];
        const asObjectFlag = true;
        // let emptyCounterAll = 0;
        gpras.forEach((gpraSection) => {

            const SELECTOR_GPRA_SECTION = 'input[type=hidden][data-gpra-section=' + gpraSection + '].no_data_to_report_flag';
            const noDataToReportFLag = parseInt($(SELECTOR_GPRA_SECTION).val());

            let empty_string = false;

            let columns = ['less_than_15','15_44','45_89', '90_179', '180_269', '270_or_more'];
            columns.forEach((column) => {
                const reportedSelector = '.gpra-edit-form.' + gpraSection + '-section input.gpra-row-0.gpra-column-' + column;
                const reportedValue = $(reportedSelector).val();
                if (reportedValue == '') {
                    empty_string = true;
                }
            });

            // skip the black check if no data to report is clicked;
            if (0 == noDataToReportFLag && empty_string == false) {

                const selector = '.gpra-edit-form.' + gpraSection + '-section input[type=text]';
                let inputs = $(selector);

                let emptyCounter = 0;

                inputs.each((index, i) => {
                    let validatedEmptyResult = validateGpraForEmpty($(i));

                    if (validatedEmptyResult.value) {
                        errors++;
                        sectionErrors[gpraSection] = i;
                        //emptyCounter++;
                    }
                });
            }
            //emptyCounterAll += emptyCounter;
        });

        const resultObject = {value: (errors == 0), sections: sectionErrors}

        return resultObject;
    }

    /**
     * As of this code's writing : ROW 0, ROW 1, and ROW 2 impose limits on each other
     * ROW 1 CANNOT exceed the value of ROW 0, ROW 2 cannot exceed value of ROW 1
     * This function returns a valid row value, if given a "parent" row and a "child" row
     * E.g : to find value ROW 1 value, we would call this function with :
     * ROW 0 as "parent" parameter, ROW 1 as "child" parameter
     */
    function getValidRowValue(parent_row_value, child_row_value)
    {
        // if row zero is null, the only valid value is null
        if(parent_row_value == null) return null;
        
        // if parent row is eq to zero, the child row can be either 0 (if entered by user) or null
        if(parent_row_value == 0) return child_row_value == 0 ? child_row_value : null;
        
        // if the child row value is a number (not null) we return the number IF :
        // it is less than or equals to the value in row_zero
        // Otherwise, we set child row value to null
        if(child_row_value != null) return child_row_value <= parent_row_value ? child_row_value : null;
        
        // lastly, if child row_value is null (which is the default, allowed value), we return it
        return null;
    }

    /**
     * This function compares the values in a GPRA table against each other
     * As of this code's writing ROW 1 and ROW 2 values in a GPRA table
     * CANNOT exceed the 'sibling' value in the row above.
     * Example : ROW 1 value cannot exceed ROW 0 value. ROW 2 must be <= ROW 1
     * This function enforces this logic by transforming invalid values when
     * needed
     */    
    function resetGpraValuesWhenInvalid(gpraSection){
        let row_zero_input_selector = `table.${gpraSection}-section input[type=hidden].gpra-row-0`;
        $(row_zero_input_selector).each(function(i, input) {
            
            let column_name = $(input).attr("data-reported-column");
            let row_one_column = `table.${gpraSection}-section input[data-gpra-column=${column_name}].gpra-row-1`;
            let row_two_column = `table.${gpraSection}-section input[data-gpra-column=${column_name}].gpra-row-2`;
            
            
            let row_zero_val = parseInt($(input).val());
            let row_one_val =  parseInt($(row_one_column).val());
            let row_two_val =  parseInt($(row_two_column).val());
            
            if(isNaN(row_zero_val)) row_zero_val = null;
            if(isNaN(row_one_val)) row_one_val = null;
            if(isNaN(row_two_val)) row_two_val = null;
            
            // we correct any invalid row 1 and row 2 values based on their relationship with each other
            $(row_one_column).val(getValidRowValue(row_zero_val, row_one_val));
            // we correct any invalid row 2 value by passing it row 1 value
            $(row_two_column).val(getValidRowValue(getValidRowValue(row_zero_val, row_one_val), row_two_val));
        });
    }
    
    function disableGpraIfEmpty(gpraSection){
        let all_inputs_empty = true;
        let row_zero_input_selector = `table.${gpraSection}-section input[type=hidden].gpra-row-0`;
        
        $(row_zero_input_selector).each(function(i, input) {
            let input_val = parseInt($(input).val());
            if(!isNaN(input_val) && input_val > 0) {
                all_inputs_empty = false;
            }
            if(isNaN(input_val)) {
                console.log("input is null. attempting to disable related rows");
                let column_name = $(input).attr("data-reported-column");
                let row_one_column = `table.${gpraSection}-section input[data-gpra-column=${column_name}].gpra-row-1`;
                let row_two_column = `table.${gpraSection}-section input[data-gpra-column=${column_name}].gpra-row-2`;
                
                console.log(row_one_column);
                $(row_one_column).prop('disabled', true);
                $(row_two_column).prop('disabled', true);
            }
        });
        if(!all_inputs_empty) return;
        
        $('#pp_'+ gpraSection).html('<p><b>You reported 0 students participating in the specific grade bands for this GPRA measure; therefore, no Outcomes data need to be entered for this GPRA table.</b></p>');
        let selector = '.gpra-edit-form.' + gpraSection + '-section';
        $(selector).prop('disabled', true);
        $(selector).css('opacity', '0.25');
        $(selector).children('input.outcomes-input').prop('disabled', true);

        $(selector).find('input').each(function(i, input) {
            $(input).prop('disabled', true);
        });     
    }
    function validateGpraSection(gpraSection, returnAsObject = false) {
        // if section is set to no data to report
        // - evaluate the first row - or the whole thing
        // as true
        // we can also add check if all values are set to zero
        const SELECTOR_GPRA_SECTION = 'input[type=hidden][data-gpra-section=' + gpraSection + '].no_data_to_report_flag';
        const noDataToReportFLag = parseInt($(SELECTOR_GPRA_SECTION).val());

        if (1 == noDataToReportFLag) {
            if (returnAsObject == true) {
                const returnValue = {'value' : true, 'elements' : []};
            }
            return true;
        }

        const selector = '.gpra-edit-form.' + gpraSection +  '-section input[type=text]';
        let inputs = $(selector);

        let errorCounter = 0;

        let failedInputs = [];

        let unavailableStudentsDataAmount = 0;

        inputs.each((index, i) => {
            let validatedInputResult = validateGpraFormInput($(i));

            if (!validatedInputResult.value) {
                errorCounter++;
                failedInputs.push(i);
            }
            unavailableStudentsDataAmount += validatedInputResult.results.unavailableStudentsDataAmount;

        });
        disableGpraIfEmpty(gpraSection);
        
        const isValid =  (errorCounter == 0);

        if (returnAsObject == true) {
            const returnValue = {
                'value' : isValid,
                'elements' : failedInputs,
                'unavailableStudentsDataAmount': unavailableStudentsDataAmount
                };
            return returnValue;
        }

        return isValid;
    }

    function validateAllGpraSections(returnValueAsObject = false) {
        let errors = 0;

        let gpras = ['gpra0', 'gpra1', 'gpra2', 'gpra3', 'gpra4', 'gpra5'];

        let returnObject = {value: errors == 0, results: {unavailableStudentsDataAmount : new Map()} };

        gpras.forEach((gpraSection) => {

            // 2024 update : add resetGpraValuesWhenInvalid, 
            // if a GPRA value is invalid correct it, 
            // and save correction to backend
            if($('#outcome-form').length > 0) {
                resetGpraValuesWhenInvalid(gpraSection);
                saveOutcomeData($('#outcome-form'), $(this), gpraSection);
            }
            
            let validatedSection = validateGpraSection(gpraSection, returnValueAsObject);

            if (returnValueAsObject) {
                if (! validatedSection.value) {
                    errors++;
                }
                returnObject.results.unavailableStudentsDataAmount.set(gpraSection, validatedSection.unavailableStudentsDataAmount);


            } else {
                if (! validatedSection ) {
                    errors++;
                }
            }
        });

        if (returnObject) {
            returnObject.value = (errors = 0);
            return returnObject;
        }

        return (errors == 0);


    }


    function validateAllGpraSectionsAndGetErrors() {
        let errors = 0;
        let sectionErrors = {};

        let gpras = ['gpra0', 'gpra1', 'gpra2', 'gpra3', 'gpra4', 'gpra5'];
        const asObjectFlag = true;
        gpras.forEach((gpraSection) => {
            const sectionResult = validateGpraSection(gpraSection, asObjectFlag);

            if (sectionResult['value'] == false) {
                errors++;
                sectionErrors[gpraSection] = sectionResult.elements;
            }
        });

        const resultObject = {value: (errors == 0), sections: sectionErrors}

        return resultObject;
    }

    function validateGpraForEmpty(element) {
        const gpraSection = $(element).attr('data-gpra-section');
        const row         = $(element).attr('data-gpra-row');
        const column      = $(element).attr('data-gpra-column');

        let nullObject = { value: false };

        const thisValue   = $(element).val();

        if (thisValue === '' || thisValue == null) {
            nullObject.value = true;
        }

        return nullObject;
    }


    /**
     * Validates the currently entered value by the input in a row above
     * @param {*} element
     * @returns
     */
    function validateGpraFormInput(element) {
        const gpraSection = $(element).attr('data-gpra-section');
        const row         = $(element).attr('data-gpra-row');
        const column      = $(element).attr('data-gpra-column');

        const thisValue   = parseInt($(element).val());
        let doNotShowNumbersFlag = true;

        // reported value
        const selectorReportedValue = '.gpra-edit-form.' + gpraSection + '-section input.gpra-row-0.gpra-column-' + column;
        const reportedValue = parseInt($(selectorReportedValue).val());

        let returnObject = {value: false, results: {unavailableStudentsDataAmount: 0}};
        // Do not check for numeric value for Textareas or Labels
        if (element[0].tagName == "TEXTAREA" || element[0].tagName == "LABEL") {
            return true;
        }
        else if (isNaN(reportedValue)) {
            // 2024 update. Null values are now allowed as input values. 
            // if the value is NaN, we set the value to an empty string, we also return true
            // since null/empty is now a valid input field
            if($(selectorReportedValue).val() !== "") $(selectorReportedValue).val("");
            returnObject.value = true;
            return returnObject;
        }

        if (row == 1) {
            // take the reported number
            if (thisValue > reportedValue) {
                showErrorStatusMessage(
                    'Error: This number cannot be greater than ' + reportedValue,
                    $(element).siblings('.status-message')
                    );

                    updateGpraImprovementsText(gpraSection, column, doNotShowNumbersFlag );
                    returnObject.value = false;
                    return returnObject;
               // return false;

            } else if (thisValue < reportedValue) {

                const difference = reportedValue - thisValue;

                //this is not considered an error anymore
                returnObject.value = true;
                returnObject.results.unavailableStudentsDataAmount = difference;

            } else {
                if (thisValue == reportedValue){
                    hideStatusMessage($(element).siblings('.status-message'));
                }

                if (reportedValue < thisValue) {
                    updateGpraImprovementsText(gpraSection, column, doNotShowNumbersFlag);
                } else {
                    updateGpraImprovementsText(gpraSection, column);
                }
            }
        }
        else if (row == 2) {
            // take the row-1 value
            const selectorFirstRow = '.gpra-edit-form.' + gpraSection + '-section input.gpra-row-1.gpra-column-' + column;

            const firstRowValue = $(selectorFirstRow).val();


            if (thisValue > firstRowValue) {
                showErrorStatusMessage(
                    'Error: This number cannot be greater than ' + firstRowValue,
                    $(element).siblings('.status-message')
                    );
                updateGpraImprovementsText(gpraSection, column, doNotShowNumbersFlag);
                returnObject.value = false;

                return returnObject;


            } else if ((thisValue == firstRowValue) && (thisValue > 0)) {
                hideStatusMessage($(element).siblings('.status-message'));
            }
            else {
                if (thisValue <= firstRowValue) {
                    hideStatusMessage($(element).siblings('.status-message'));
                }
            }
        }

        returnObject.value = true;
        return returnObject;
    }

    function updateGpraImprovementsText(gpraSection, column, showUncalculated = false)
    {

        let firstRowValue = '?';
        let secondRowValue = '?';
        const thirdRowValueSelector  = '.gpra-edit-form.' + gpraSection + '-section td.gpra-row-3.gpra-column-' + column + ' span.amount';

        if (showUncalculated == false) {
            firstRowValue     = $('.gpra-edit-form.' + gpraSection + '-section input.gpra-row-1.gpra-column-' + column).val();
            secondRowValue    = $('.gpra-edit-form.' + gpraSection + '-section input.gpra-row-2.gpra-column-' + column).val();
        }

        $(thirdRowValueSelector).html(secondRowValue + ' out of ' + firstRowValue);
    }

    /**
     * ran on start
     */
    function calculateImprovementsResultsValues() {
        let gpras = ['gpra0','gpra1', 'gpra2', 'gpra3', 'gpra4', 'gpra5'];

        gpras.forEach((gpraSection) => {
            calculateGpraSectionImprovements(gpraSection);
        });
    }


    /**
     * Calculates the improvements section for GPRA
     * @param {*} gpraSection
     */
    function calculateGpraSectionImprovements(gpraSection) {

        let columns = ['less_than_15','15_44','45_89', '90_179', '180_269', '270_or_more'];

        columns.forEach((column) => {
            const firstRowSelector = '.gpra-edit-form.' + gpraSection + '-section input.gpra-row-1.gpra-column-' + column;
            const secondRowSelector= '.gpra-edit-form.' + gpraSection + '-section input.gpra-row-2.gpra-column-' + column;
            const thirdRowSelector = '.gpra-edit-form.' + gpraSection + '-section td.gpra-row-3.gpra-column-' + column + ' span.amount';

            const firstValue = $(firstRowSelector).val();
            const secondValue = $(secondRowSelector).val();
            const theText = secondValue + ' out of ' + firstValue ;


            $(thirdRowSelector).html(theText);

        });
    }

    function resetGpraSectionInputs(gpraSection)
    {
        const VALUE = '';
        const selector = '.gpra-edit-form.' + gpraSection + '-section input[type=text], #char_' + gpraSection;

        $(selector).val(VALUE);

        // clear all error messages
        const selectorMessages = '.gpra-edit-form.' + gpraSection + '-section .status-message';
        $(selectorMessages).hide();

        // reset character counter
        $("#left_char_" + gpraSection).html(10000);

    }

    /**
     * Outcomes Input
     */
         $(document).on('keyup', '.outcomes-input.numbersonly', function (e) {
            e.preventDefault();

            this.value = this.value.replace(/[^0-9\.]/g,'');
        });


        /**
         * toggles the GPRA edit form availability on and off
         */
        function enableGpraFormSection(gpraSection, enabled) {

            let selector = '.gpra-edit-form.' + gpraSection + '-section';

            if (enabled) {
                $(selector).prop('disabled', false);
                $(selector).css('opacity', '1');
                $(selector).children('input').prop('disabled', false);

                $(selector).find('input').each(function(i, input) {

                    $(input).prop('disabled', false);
                });
            } else {
                $(selector).prop('disabled', true);
                $(selector).css('opacity', '0.25');
                $(selector).children('input.outcomes-input').prop('disabled', true);

                $(selector).find('input').each(function(i, input) {
                    $(input).prop('disabled', true);

                });
            }

        }

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    // wait until DOM is ready

    function validateNoDataToReport() {

        let data = $('#outcome-form ').serializeArray();
        let $fieldsToCheck = $('#outcome-form').find('input').not('input[type=hidden]').map (function(i, o) {
            if (!isNumeric(o.value) || o.value > 10000) {
                $(this).addClass('outcomes-input-error');
                return o;
            } else {
                $(this).removeClass('outcomes-input-error');
            }
        });

        return $fieldsToCheck.length == 0;

    }





    $('a.gpra-error-section-link').on('click',  function (e) {
        e.preventDefault();

        const gpra = $(this).attr('data-gpra-section');

        $('.gpra-edit-form.' + gpra + '-section').attr('tabindex', -1).focus();
    });

    $('.container-unavailable-student-data textarea.unavailable-student-data').on('keyup',  function (e) {
        e.preventDefault();
        let id = 'char_' + $(this).attr('data-gpra-section');
        updateUnavailableStudentDataCharsLeftAmount(this, id);
    });

    function updateUnavailableStudentDataCharsLeftAmount(element, id) {
        const LIMIT = 10000;

        let charsLeft = LIMIT - $(element).val().replace(/(\r\n|\n|\r)/g,"").length;

        if (charsLeft < 0 ) {
            $(element).val($(element).val().substring(0, LIMIT));
        }
        $('#'+'left_'+id).html(charsLeft);
    }

    function getUnavailableStudentDataCharsLeftAmount() {
        $(".unavailable-student-data" ).each(function() {
            let id = this.id;
            updateUnavailableStudentDataCharsLeftAmount(this, id);
        });
    }
    getUnavailableStudentDataCharsLeftAmount();

    function toggleShowUnavailableStudentDataTextArea(gpraSection, unavailableStudentsAmount = 0)
    {
        const SELECTOR_DIV = 'div.container-unavailable-student-data[data-gpra-section=' + gpraSection + ']';
        let missingStudents = parseInt(unavailableStudentsAmount);
        if (missingStudents > 0) {

            $(SELECTOR_DIV).css('display' ,'block');
            const SELECTOR_TEXTAREA  =  SELECTOR_DIV + ' textarea';
            updateUnavailableStudentDataCharsLeftAmount($(SELECTOR_TEXTAREA));
            $(SELECTOR_DIV + ' p span.unavailable-students-amount').html(unavailableStudentsAmount);
            
        }
    }

    const OutcomeValidation = {
        showErrorMessage : () => {
        }
    }

    // On edit outcome and view outcome, wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
    if ($(".edit-outcome.wrapper, .view-outcome.wrapper").length >= 1) {
        // wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
        $(window).on("load", function () {
            // OPTIONAL - waits til next tick render to run code (prevents running in the middle of render tick)
            window.requestAnimationFrame(function () {
                activitiesComplete.play();
                activitiesComplete.restart();
                staffingComplete.play();
                staffingComplete.restart();
                participationComplete.play();
                participationComplete.restart();
            });
        });
    }

    let api_state_flag = 0;
    let view_only_mode_flag = 0;
    $.fn.outcomes_view_only = function () {
        let form = this;
        api_state_flag = $(form).find('input[name="api_state_flag"]').val();
        view_only_mode_flag = $(form).find('input[name="view_only_mode_flag"]').val();
        showlog('outcome.js => the api_state_flag IS SET to ' + api_state_flag);
        showlog('outcome.js => the view_only_mode_flag IS SET to ' + view_only_mode_flag);
        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            $(".outcomes-input").attr('disabled','disabled');
            $("label.checker").removeAttr('tabindex');
            console.log('disbaled by flag');
        }
    }

    $('#outcome-form').outcomes_view_only();

})(jQuery);


